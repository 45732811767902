
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },

    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      swiperOptions: {
        speed: 500,
        loop: true,
        autoheight: true,
        pagination: {
          el: '.hero-swiper-pagination',
          clickable: true,
        },
      },
    }
  },
}
