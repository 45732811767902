
import ProductFunctions from '~/mixins/ProductFunctions'

export default {
  mixins: [ProductFunctions],
  props: {
    data: {
      type: Object,
      required: true,
    },

    hasDotNav: {
      type: Boolean,
      default: false,
    },

    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      hasBreadcrumbs: true,
    }
  },

  computed: {
    hasBgImage() {
      if (this.data.background_image) {
        return true
      } else {
        return false
      }
    },

    titleLengthClass() {
      if (this.data.title?.length > 40) {
        return 'lg:text-10xl'
      }

      if (this.data.title?.length > 24) {
        return 'lg:text-12xl'
      }

      return 'lg:text-14xl'
    },
  },
}
