
const COLORS = {
  white: {
    value: 'white',
    label: 'Weiss',
    key: 'white',
  },
  lightGray: {
    value: 'gray-light',
    label: 'Hellgrau',
    key: 'gray-light',
  },
}

export default {
  props: {
    components: {
      type: Array,
      default: () => [],
    },

    backgroundColor: {
      type: Object,
      default: null,
    },

    alternatingBackgroundColor: {
      type: Boolean,
      default: false,
    },

    isProductPage: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    mappedComponents() {
      return this.components?.map((component, i) => {
        component.component_name = component.type.replace(/_/g, '-')

        // If alternatingBackgroundColor is turned on and the component itself does not have a background color defined
        if (
          this.alternatingBackgroundColor &&
          !component.background_color?.key
        ) {
          // TODO: COMPONENT AFTER COMPONENT WITH BACKGROUND_COLOR FROM CMS SHOULD BE WHITE
          component.background_color = i % 2 ? COLORS.lightGray : COLORS.white
        }
        // If the component loader has a background color
        if (this.backgroundColor?.key) {
          component.background_color = this.backgroundColor
        }

        return component
      })
    },

    windowHeight() {
      return this.$store.getters['product-fixed-nav/windowHeight']
    },
  },

  methods: {
    visibilityChanged(isVisible, entry) {
      const payload = {
        isVisible,
        entry,
      }
      this.$emit('visibility-changed', payload)
    },
  },
}
