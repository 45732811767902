
import { mapState } from 'vuex'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState('global', ['footerSettings']),
  },
}
